<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/layout/header.vue'
import Footer from './components/layout/footer.vue'

export default {
  name: 'App',
  components: {
    Header, Footer
  },
}
</script>
<style>
#app {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #1a1a1a;
  margin: 0px;
  padding: 0px;
}
body{
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  text-align: center;
  color: #1a1a1a;
  background-color: #FEFEFE;
  margin: 0px;
  padding: 0px;
}
.w{
  width: 1190px;
  margin: 0px auto;
}
a{ color: #1A1A1A; text-decoration: none;}
a:hover{ color:#1296db; text-decoration: underline;}
.clearfix{ clear: both;}
.banner{ background-color: #004172; height: 500px;}
</style>
