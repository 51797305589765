<template>
    <div>
    <div :style= "{width: config.width, height: config.height,border: '15px #3072b9 solid'}" class="tpl" v-show="this.kefu_box_show">
        <div class="small-chat-box fadeInRight animated">
            <div class="heading" draggable="true">
                <small class="chat-date pull-right" @click="handleHideChat"><i class="iconfont icon-subtract"></i></small>
                <span id="kefu-title">{{ this_title }}</span>
            </div>
            <div class="slimScrollDiv" id="chat-content-box">
                <div class="chat-box kefu-chat-main" id="kefu-chat-list" ref="chatList">
                    <ul style="display: block;">
                        <li v-for="(item,index) in this_chat_list" :key="index" :class="item.class">
                            <div v-if="item.type === 'content'" class="kefu-chat-system"><span v-html="item.message"></span></div>
                            <div v-else-if="item.type === 'chat'" class="kefu-chat-user"><img :src="item.avatar" /><div class="kefu-chat-text" @click="showBigPic" v-html="item.message"></div></div>
                            <div v-else-if="item.type === 'system'" class="kefu-chat-system"><span v-html="item.message"></span></div>
                            <div v-else></div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="form-chat">
                <div class="input-group-b">
                    <span class="iconfont icon-emoji" @click="showFaces"></span>
                    <span class="iconfont icon-pic" @click="uploadImg"><input type="file" id="kefu-upload" name="file" style="display:none" ></span>
                </div>
                <textarea class="form-control chat-area" id="msg_area" :readonly="msg_area_readonly" v-model="msg_area"></textarea>
                <div class="input-group">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="sendMsg">发送</button>
                    </span>
                </div>
            </div>
            <div class="bar"></div>        
            <div class="face-box" v-show="face_box">
                <ul class="layim-face-list">
                    <li v-for="item in face_list" :key="item" :title="item.key" @click="handleClickFace">
                        <img :src="item.src">
                    </li>
                </ul>
            </div>
        </div>            
    </div>
    <img :src="bigimg.src" :width="bigimg.width" :height="bigimg.height" :alt="bigimg.alt" class="kefu-bigimg" v-show="bigimg.show" @click="handleHideBigImg" /><div @click="handleHideBigImg" class="kefu-mask"  v-show="bigimg.show"></div>
    <i class="kefu-newMsg iconfont icon-service" title="在线客服,点击打开聊天窗口" @click="handleHideChat" v-show="this_chat_show"></i>'
    </div>
</template>
<script>
import "../../assets/css/kefu.css";
import $ from 'jquery'
import md5 from 'js-md5';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { mapState } from 'vuex'

export default {
    props: ['options'],
    data: function () {
        return {
            kf_id: 0,
            kf_name: ''
            ,this_title:'与 客服 交流中'
            ,this_chat_list: []
            ,msg_area: ''
            ,this_chat_show: true
            ,face_box: false
            ,msg_area_readonly: false
            ,face_list: []
            ,socket: null
            ,uinfo: null //用户信息
            ,time: 0
            ,timer: 0 //通知定时器
            ,token_key: '27f7822d21e133c2db787b4aaa4b6839'
            ,bigimg: {
                show: false,
                width: 0,
                height: 0,
                alt: ''
            }
            ,config: {
                uid: this.options.uid?this.options.uid:'001',//用户id
                name: this.options.name?this.options.name:'guest',//用户name
                avatar: this.options.avatar && this.options.avatar != ''?this.options.avatar:'/static/customer/images/me.png',//用户头像
                group: this.options.group?this.options.group:'1',//客服分组
                socket: this.options.socket?this.options.socket:'7272',//聊天服务器地址
                face_path: this.options.face_path?this.options.face_path:'',//表情地址
                upload_url: this.options.upload_url?this.options.upload_url:'',
                root_path: this.options.root_path?this.options.root_path:'',
                height: this.options.height?this.options.height:'500px',//窗口高度
                width: this.options.width?this.options.width:'450px', //窗口宽度 
            }
        }
    },
    watch:{
        kefu_box_show: function(val){
            var __this = this;
            if(val && __this.socket == null)
                __this.register();
        }
    },
    computed: mapState([
        'kefu_box_show'
    ]),
    methods: {
        token: function()
        {
            var __this = this;
            var d = new Date;
            return md5(md5(__this.token_key+d.getFullYear()+(d.getMonth()+1)+d.getDate()+d.getHours()+d.getMinutes()))//md5(md5(chat.eqixing.cn_client_001Ymd))
        },
        check: function () {
            var __this = this;
            if('' == this.config.url || 0 == __this.config.group || '' == __this.config.socket)
            {
                console.log("配置文件错误");
                return false;
            }        
            if (!__this.getCache('kefu-UserId')) {//是否已分配账号
                __this.config.uid = __this.config.uid || __this.setMember()+parseInt(Math.random() * 40) + 1;
                __this.config.name = __this.config.name || '会员'+__this.config.uid;
                __this.cacheChat({key:"kefu-UserId",data:[{'userId':__this.config.uid,'name': __this.config.name,'avatar': __this.config.avatar && __this.config.avatar != ''?__this.config.avatar:__this.config.root_path+"/static/customer/images/me.png"}]});
            }
            if (!__this.getCache('nowMinutes')) {//显示消息时间
                __this.cacheChat({key: "nowMinutes",data:{'now': __this.getMinutes()}}); 
            }        
        },
        setKefuBoxShow: function(status){
            this.$store.commit("setKefuStatus",status);
        },
        cacheChat: function (obj) {
            if(typeof(Storage) !== "undefined") {
                localStorage.setItem(obj.key, JSON.stringify(obj.data));
            }
        },
        getCache: function (key) {
            return JSON.parse(localStorage.getItem(key));
        },
        register: function () {//注册监听事件
            var __this = this;
            __this.check();
            __this.isMobile();
            __this.isVisible();
            var protocol = location.protocol === 'https:' ? 'wss://' : 'ws://';
            __this.socket = new ReconnectingWebSocket(protocol + __this.config.socket,null,{debug: false});//创建Socket实例        
            __this.uinfo = __this.getCache('kefu-UserId');
            __this.socket.onopen = function () {
                var login_data = {
                    type: "userInit",
                    token: __this.token(),
                    uid: (__this.uinfo.length > 0?__this.uinfo[0].userId:''),
                    name: (__this.uinfo.length > 0?__this.uinfo[0].name:''),
                    avatar: __this.config.avatar,
                    group: __this.config.group
                };
                __this.socket.send(JSON.stringify(login_data));
                __this.ping(30000);
                __this.isLock(false);
            };
            __this.socket.onmessage = function (res) {
                var data = eval("("+res.data+")");
                switch(data['message_type']) {
                    // 已经被分配了客服
                    case 'connect':
                        __this.kf_id = data.data.kf_id;
                        __this.kf_name = data.data.kf_name;
                        __this.showSystem({content: '客服 ' + __this.kf_name + ' 为您服务'});
                        __this.__this_title = '与 ' + __this.kf_name + ' 交流中';
                        __this.showChatLog();
                        break;
                    // 排队等待
                    case 'wait':
                        __this.isLock(true);
                        if('暂时没有客服上班,请稍后再咨询。' == data.data.content) {
                            __this.socket.close();
                        }
                        __this.__this_title = '暂时没有客服';
                        __this.showSystem(data.data);
                        break;
                    // 监测客服上线
                    case 'kf_online':
                        if (data.data.kf_group == __this.config.group) {
                            __this.kf_id = data.data.kf_id;
                            __this.kf_name = data.data.kf_name;
                            __this.this_title = '与 ' + __this.kf_name + ' 交流中';
                            __this.showSystem({content: '客服 ' + __this.kf_name + ' 为您服务'});
                            __this.wordBottom();
                            __this.isLock(false);
                        }
                    
                        break;                    
                    // 监测客服下线
                    case 'kf_offline':
                        if (data.data.kf_group == __this.config.group) {
                            __this.kf_id = data.data.kf_id;
                            __this.kf_name = data.data.kf_name;
                            __this.this_title = '暂时没有客服';
                            __this.showSystem({content: '客服 ' + __this.kf_name + ' 暂时停止服务'});
                            __this.wordBottom();
                            __this.isLock(true);
                        }
                    
                        break;                    
                    // 监测聊天数据                
                    case 'chatMessage':
                        __this.showMsg(data.data);
                        break;
                    // 问候语
                    case 'helloMessage':
                        __this.showMsg(data.data);
                        break;
                    // 转接
                    case 'relinkMessage':
                        __this.this_title = '正在转接中...';
                        break;
                }            
            };

            __this.socket.onclose = function () {
                __this.isLock(true);
                console.log('连接断开');
            };
        },
        setMember:  function () {
            var __this = this;
            var d = new Date(new Date());
            return  __this.digit(d.getDate()) + __this.digit(d.getHours()) + __this.digit(d.getMinutes()) + __this.digit(d.getSeconds());        
        },
        isLock: function (state) {
            var __this = this;
            state?(__this.msg_area_readonly = 'readonly'):(__this.msg_area_readonly = false);
        },
        ping: function (time) {
            var __this = this;
            //每30秒ping服务器
            setInterval(function ()  {
                __this.socket.send(JSON.stringify({type:"ping",token:__this.token()}));
            },time);
        },
        showSystem: function (msg) {
            if(!msg || msg.content == '')
                return ;
            var __this = this;
            __this.this_chat_list.push({type: 'system',message: msg.content});
        },
        showChatLog: function () {// 展示本地聊天缓存
            var __this = this;
            var chatLog = __this.getCache(__this.kf_id + '-' + __this.uinfo[0].userId);
            if(chatLog == null || chatLog.length == 0){
                return ;
            }
            var len = chatLog.length;
            for(var i = 0; i < len; i++){
                var v = chatLog[i];
                let classs = '';
                let _html = '';
                let _type = '';
                let _avatar = '';
                let _name = '';
                if ('time' !== v.type) {
                    if ('mine' != v.type) {
                        classs = 'kefu-chat-mine';
                    }
                    _type = 'chat';
                    _avatar = v.avatar;
                    _name = v.name;
                    _html = v.content;
                }else{
                     _type = 'system';
                    var data = __this.getTimeText(v.time); 
                    _html = data;
                }
                __this.this_chat_list.push({class: classs,message: _html,type: _type, avatar: __this.config.root_path+_avatar, name: _name});
            }
            // 滚动条自动定位到最底端
            __this.wordBottom();
        },
        sendMsg: function () {// 发送信息
            var __this = this;
            (typeof(__this.msg_area_readonly) == undefined || !__this.msg_area_readonly)?__this.isLock(false):__this.isLock(true);
            var msg = __this.msg_area;
            if('' == msg) return false;
            var content = __this.replaceContent(msg);    
            var time = __this.getDate();    
            var word =__this. msgFactory(content, 'mine',time,__this.uinfo[0]);
            if(word)
            {
                // 发送消息
                __this.socket.send(JSON.stringify({
                    type: 'chatMessage',
                    token: __this.token(),
                    data: {to_id: __this.kf_id, 
                            to_name: __this.kf_name, 
                            content: msg, 
                            from_name: __this.uinfo[0].name,
                            from_id: __this.uinfo[0].userId, 
                            from_avatar: __this.config.avatar}
                }));
                __this.this_chat_list.push(word);
            }
            __this.msg_area = '';
            // 滚动条自动定位到最底端
            __this.wordBottom();
        },
        sendPicMsg: function(sendMsg)
        {
            var __this = this;
            (typeof(__this.msg_area_readonly) == undefined || !__this.msg_area_readonly)?__this.isLock(false):__this.isLock(true);
            var msg = (typeof(sendMsg) == 'undefined') ? __this.msg_area : sendMsg;
            if('' == msg) return false;
            var content = __this.replaceContent(msg);    
            var time = __this.getDate();    
            var word =__this. msgFactory(content, 'mine',time,__this.uinfo[0]);
            if(word)
            {
                // 发送消息
                __this.socket.send(JSON.stringify({
                    type: 'chatMessage',
                    token: __this.token(),
                    data: {to_id: __this.kf_id, 
                            to_name: __this.kf_name, 
                            content: msg, 
                            from_name: __this.uinfo[0].name,
                            from_id: __this.uinfo[0].userId, 
                            from_avatar: __this.config.avatar}
                }));
                __this.this_chat_list.push(word);
            }
            __this.msg_area = '';
            // 滚动条自动定位到最底端
            __this.wordBottom();
        },
        showMsg: function (info) {// 展示收到的消息
            var __this = this;
            // 清除系统消息
            __this.clearMsg();//清除已有提醒
            var content = __this.replaceContent(info.content);
            var word = __this.msgFactory(content, 'other',info.time,info);
            __this.this_chat_list.push(word);
            document.hidden && __this.newMsg();
            // 滚动条自动定位到最底端
            __this.wordBottom();
        },
        msgFactory: function (content, type,time,info) {// 消息体    
            var __this = this;
            if(content === '' || content === undefined)
                return '';
            // 储存信息
            var key = __this.kf_id + '-' + __this.uinfo[0].userId;
            if(typeof(Storage) !== "undefined"){
                var localMsg = __this.getCache(key);
                if(localMsg == null || localMsg.length == 0){
                    localMsg = [];
                }
                //(type == 'mine') && (name == '我');
                if (__this.getCache('nowMinutes').now != __this.getMinutes()) {//消息发送接收时间大于当前记录时间
                    var data = {}; 
                    data.content = __this.getTimeText(time);
                    __this.showSystem(data);
                    localMsg.push({type: 'time', time: time,});
                    __this.cacheChat({key: "nowMinutes",data:{'now': __this.getMinutes()}});//更新时间
                }
                localMsg.push({type: type, name: info.name, time: time, content: content,avatar:info.avatar,class: ''});
                __this.cacheChat({key: key, data: localMsg});
            }        
            var _html = '';
            var _class = '';
            let _type = '';
            let _avatar = '';
            let _name = '';
            if ('time' !== type) {
                if ('mine' != type) {
                    _class = 'kefu-chat-mine';
                }
                _type = 'chat';
                _avatar = __this.config.root_path+info.avatar;
                _name = info.name;
                _html = content;
            }else{
                _type = 'system';
                _html = data.content;
            }
            return {class: _class, message: _html,type: _type, avatar: _avatar, name: _name};
        },
        mGetDate: function ()  {// 获取天数
            return Math.ceil(( new Date() - new Date(new Date().getFullYear().toString()))/(24*60*60*1000))+1;
        },
        getMinutes: function ()  {// 获取当前分钟
            var __this = this;
            var d = new Date(new Date());
            var hour = 60;//1小时
            var day = 24*hour;//1天
            var totalDay = __this.mGetDate()-1;//共多少天
            var totalHour = __this.digit(d.getHours());
            var totalMinutes = __this.digit(d.getMinutes());
            return totalDay*day+totalHour*hour+totalMinutes;
        },
        getDate: function ()  {// 获取日期
            var __this = this;
            var d = new Date(new Date());
            return d.getFullYear() + '-' + __this.digit(d.getMonth() + 1) + '-' + __this.digit(d.getDate())
                + ' ' + __this.digit(d.getHours()) + ':' + __this.digit(d.getMinutes()) + ':' + __this.digit(d.getSeconds());
        },
        digit: function (num) {//补齐数位
            return num < 10 ? '0' + (num | 0) : num;
        },
        getTimeText: function (time) {
            var __this = this;
            var timeS = time;
            var todayT = ''; //
            var yestodayT = '';
            var d = new Date(new Date());
            var year = '';
            var month = '';
            var day = '';
            var timeCha = __this.getTimeS(timeS);
            timeS = timeS.slice(-8);
            year = time.slice(0,4);
            month = time.slice(5,7);
            day = time.slice(8,10);
            todayT = __this.digit(d.getHours())*60*60*1000 + __this.digit(d.getMinutes())*60*1000 + __this.digit(d.getSeconds())*1000;
            yestodayT = todayT + 24*60*60*1000;
            if(timeCha > yestodayT) {
                if(year < d.getFullYear())
                {
                    return timeS.slice(0,2)>12?year+'年'+month+'月'+day+'日'+' 下午'+(timeS.slice(0,2)==12 ? 12 : timeS.slice(0,2) - 12)+timeS.slice(2,5):year+'年'+month+'月'+day+'日'+' 上午'+timeS.slice(0,5);
                }else{
                    return timeS.slice(0,2)>12?month+'月'+day+'日'+' 下午'+(timeS.slice(0,2)==12 ? 12 : timeS.slice(0,2) - 12)+timeS.slice(2,5):month+'月'+day+'日'+' 上午'+timeS.slice(0,5);
                }
                
            }
            if(timeCha > todayT && timeCha < yestodayT) {
                return '昨天 '+timeS.slice(0,5);
            }
            if(timeCha < todayT) {
                return timeS.slice(0,5);
            }
        },
        getTimeS: function (argument) {// 日期转时间戳
            var timeS = argument;
            timeS = timeS.replace(/[年月]/g,'/').replace(/[日]/,'');
            return new Date().getTime() - new Date(timeS).getTime() - 1000; //有一秒的误差
        },
        wordBottom : function () {// 对话框定位到最底端
            $("#kefu-chat-list").animate({scrollTop: $("#kefu-chat-list ul").height()+150},500);
        },
        replaceContent: function (content) {// 转义聊天内容中的特殊字符
            var __this = this;
            // 支持的html标签
            var html = function (end) {
                return new RegExp('\\n*\\[' + (end || '') + '(pre|div|span|p|table|thead|th|tbody|tr|td|ul|li|ol|li|dl|dt|dd|h2|h3|h4|h5)([\\s\\S]*?)\\]\\n*', 'g');
            };
            content = (content || '').replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
                                     .replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/"/g, '&quot;') // XSS
                                     .replace(/face\[([^\s]+?)\]/g, function (face) {  // 转义表情
                                        var alt = face.replace(/^face/g, '');
                                        return '<img alt="' + alt + '" title="' + alt + '" src="' + __this.getFaces()[alt] + '">';
                                     })
                                     .replace(/img\[([^\s]+?)\]/g, function (img) {  // 转义图片
                                        return '<img class="kefu-img" src="' + img.replace(/(^img\[)|(\]$)/g, '') + '" width="100px" />';
                                     })
                                     .replace(/file\([\s\S]+?\)\[[\s\S]*?\]/g, function (str) { // 转义文件
                                        var href = (str.match(/file\(([\s\S]+?)\)\[/) || [])[1];
                                        var text = (str.match(/\)\[([\s\S]*?)\]/) || [])[1];
                                        if (!href) return str;
                                        return '<a class="kefu-file" href="' + href + '" download target="_blank"><i class="layui-icon">&#xe61e;</i><cite>' + (text || href) + '</cite></a>';
                                     }).replace(html(), '<$1 $2>').replace(html('/'), '</$1>') // 转移HTML代码
                                     .replace(/\n/g, '<br>') // 转义换行
            return content;
        },
        showFaces: function () {// 展示表情数据
            var __this = this;
            if (__this.msg_area_readonly == 'readonly') return false;
            if(__this.face_box)
            {
                __this.face_box = false;
                return ;
            }
            var faces = __this.getFaces();
            __this.face_list = [];
            for(var key in faces){
                __this.face_list.push({key: key,src: faces[key]})
            }
            __this.face_box = true;
        },
        getFaces: function () {// 表情替换
            var __this = this;
            var alt = ["[微笑]", "[嘻嘻]", "[哈哈]", "[可爱]", "[可怜]", "[挖鼻]", "[吃惊]", "[害羞]", "[挤眼]", "[闭嘴]", "[鄙视]", "[爱你]", "[泪]", "[偷笑]", "[亲亲]", "[生病]", "[太开心]", "[白眼]", "[右哼哼]", "[左哼哼]", "[嘘]", "[衰]", "[委屈]", "[吐]", "[哈欠]", "[抱抱]", "[怒]", "[疑问]", "[馋嘴]", "[拜拜]", "[思考]", "[汗]", "[困]", "[睡]", "[钱]", "[失望]", "[酷]", "[色]", "[哼]", "[鼓掌]", "[晕]", "[悲伤]", "[抓狂]", "[黑线]", "[阴险]", "[怒骂]", "[互粉]", "[心]", "[伤心]", "[猪头]", "[熊猫]", "[兔子]", "[ok]", "[耶]", "[good]", "[NO]", "[赞]", "[来]", "[弱]", "[草泥马]", "[神马]", "[囧]", "[浮云]", "[给力]", "[围观]", "[威武]", "[奥特曼]", "[礼物]", "[钟]", "[话筒]", "[蜡烛]", "[蛋糕]"], arr = {};
            $.each(alt, function (index, item) {
                arr[item] = __this.config.face_path + index + '.gif';
            });
            return arr;
        },
        handleHideBigImg: function () {
            var __this = this;
            __this.bigimg.show = false;
            __this.bigimg.width = 0;
            __this.bigimg.height = 0;
            __this.bigimg.src = '';
        },
        showBigPic: function (e) {// 双击图片
            var __this = this;
            if(e.target.className === 'kefu-img')
            {
                __this.bigimg.show = true;
                __this.bigimg.width = e.target.height*5;
                __this.bigimg.height = e.target.width*5;
                __this.bigimg.src = e.target.src;
            }
        },
        uploadImg: function () {
            var __this = this;
            if(__this.msg_area_readonly) return false;            
            $("#kefu-upload").click();
            $("#kefu-upload").unbind().change(function () {
                var formData = new FormData();
                var file = $("#kefu-upload")[0].files[0];
                formData.append('token',__this.token());
                formData.append("file",file); //传给后台的file的key值是可以自己定义的
                __this.filter(file) && $.ajax({
                    url: __this.config.upload_url || '',
                    type: "post",
                    data: formData,
                    contentType: false,
                    processData: false,
                    dataType: 'JSON',
                    mimeType: "multipart/form-data",
                    success: function (res) {
                        __this.sendPicMsg('img[' + res.data.src + ']');
                        __this.showBigPic();
                    },
                    error: function (data) {
                        console.log(data);
                    }
                }); 
            });
            
        },
        filter: function (obj) {
            var __this = this;
            var imgType = ["image/jpeg","image/png"];
            var filetypes = __this.config.imgType|| imgType;
            //var isnext = false;
            for (var i = 0; i < filetypes.length; i++) {
                if (filetypes[i] == obj.type) {
                    return true;
                }
            }  
            return false;  
        },
        isMobile: function () {
            var __this = this;
            // 是否移动端访问
            if( navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ){
                __this.config.width = '100%';
                __this.config.height = '100%';
            }
        },
        isVisible: function () {//当前窗口是否可见
            var __this = this;
            $(document).on('visibilitychange', function (e) {
                if (e.target.visibilityState === "hidden" && __this.this_chat_show) {
                    __this.newMsg();
                }else{
                    __this.clearMsg();
                }
            });
        },
        newMsg: function () {//消息提醒
            var __this = this;
            var title = document.title.replace("【　　　】", "").replace("【新消息】", "");  
            // 定时器，设置消息切换频率闪烁效果就此产生  
            let timer = setTimeout(function () {  
                __this.time++;  
                __this.newMsg();
                if (__this.time % 2 == 0) {  
                    document.title = "【新消息】" + title  
                }else {  
                    document.title = "【　　　】" + title  
                }  
            }, 600);  
            return timer;
        },
        clearMsg: function () {// 清除消息提示
            var __this = this;
            clearTimeout(__this.timer);  
            document.title = document.title.replace("【　　　】", "").replace("【新消息】", ""); 
        },
        handleClickFace: function (e) {
            var __this = this;
            var content = e.currentTarget.title;
            var contentvalue = __this.msg_area;
            __this.msg_area = contentvalue +'face'+ content;
            __this.face_box = false;
            return false;
        },
        handleHideChat: function() {
            var __this = this;
            if(__this.kefu_box_show)
            {
                __this.setKefuBoxShow(false);
                __this.this_chat_show = true;
            }else{
                __this.setKefuBoxShow(true);
                __this.this_chat_show = false;
                __this.wordBottom();
            }
        }
    }
}
</script>
