import { createRouter, createWebHistory } from 'vue-router'

const index = () => import('./components/index')
const login = () => import('./components/login')
const solution = () => import('./components/solution')
const business = () => import('./components/business')
const brand = () => import('./components/brand')
const news = () => import('./components/news')
const newsDetail = () => import('./components/news-detail')
const about = () => import('./components/about')
const product = () => import('./components/product')
const product_it = () => import('./components/product/it')
const product_brand = () => import('./components/product/brand')
const product_business = () => import('./components/product/business')
const product_marketing = () => import('./components/product/marketing')
const product_technology = () => import('./components/product/technology')

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/solution',
    name: 'solution',
    component: solution
  },
  {
    path: '/business',
    name: 'business',
    component: business
  },
  {
    path: '/brand',
    name: 'brand',
    component: brand
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/news/detail',
    name: 'news_detail',
    component: newsDetail
  },
  {
    path: '/detail',
    name: 'detail',
    component: newsDetail
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/product/brand',
    name: 'product_brand',
    component: product_brand
  },
  {
    path: '/product/it',
    name: 'product_it',
    component: product_it
  },
  {
    path: '/product/business',
    name: 'product_business',
    component: product_business
  },
  {
    path: '/product/marketing',
    name: 'product_marketing',
    component: product_marketing
  },
  {
    path: '/product/technology',
    name: 'product_technology',
    component: product_technology
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})
export default router
