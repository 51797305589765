<template>
	<div class="footer">
		<div class="w fcontent">
			<div class="fdesc">
				<span>深圳<br />总部: 广东省深圳市中广核大厦9层</span>
				<span>成都<br />分公司: 成都市高新区天府四街158号OCG国际中心B座903</span>
				<span>香港<br />分公司: 香港九龙观塘鸿图道51号保华企业中心24楼</span>
				<span>北京<br />分公司: 北京市朝阳区望京国际中心B座1805</span>
				<span>长沙<br />分公司: 长沙市岳麓区科技产业园9#901-905</span>
				<span>上海<br />分公司: 上海市虹口区花园路128号六街区B座202</span>
			</div>
			<div class="flink"><img src="../../assets/qr_customer.png" /><img src="../../assets/qr_compay.png" /></div>
			<div class="clearfix"></div>
		</div>
		<div class="w copyright">Copyright © 2012-2021 深圳市亿企兴网络科技有限公司 All Rights Reserved.    备案号粤ICP备2021046668号</div>
	</div>
	<div class="fcopyright">本网站由阿里云提供云计算及安全服务  本网站支持 IPv6  Powered by CloudDream</div>
	<div id="laykefu-min"><Customer :options="{
		group: 1,
		socket: 'chat.eqixing.cn/wss',
		face_path: '/images/face/',
		root_path: 'https://chat.eqixing.cn',
		avatar: '/static/customer/images/me.png',
		upload_url: 'https://chat.eqixing.cn/index/upload/uploadImg'
	}"></Customer></div>
</template>

<script>
import Customer from "./customer";
export default {
	components: { Customer },
}
</script>

<style>
.footer{ background-color: #242525; padding: 20px 0px;}
.fcontent{ padding: 20px 0px; border-bottom: 1px #333333 solid;}
.fcontent .fdesc{ width: 65%; color: #ffffff; float: left;}
.fcontent .fdesc span{ display: block; width: 50%; float: left; line-height: 24px; font-size: 14px;}
.fcontent .flink{ width: 30%; float: right;}
.fcontent .flink img{ background-color: #ffffff; padding: 10px; margin-left: 10px;}
.footer .copyright{ border-top: 1px #030000 solid; color: #ffffff; font-size: 14px; line-height: 40px; padding: 10px 0px;}
.fcopyright{ text-align: center; padding: 30px 0px; font-size: 14px;}

#im{ position: fixed; z-index: 9999; width: 500px; height: 500px; right: 10px; bottom: 10px; border: 15px #eeeeee solid; background-color: #ffffff;}
#im .t{ height: 40px; line-height: 40px; padding-left: 10px; font-weight: bold; background-color: #3072b9; color: #ffffff;}
#im .t span{ float: right; padding-right: 10px; cursor: pointer;}
#im .c{ height: 280px; overflow-y: auto; padding: 20px;}
#im .c ul,#im .c ul li{ margin: 0px; padding: 0px; list-style: none;}
#im .c ul li{ display: flex; margin-bottom: 10px; clear: both;}
#im .c ul li .himg{width: 30px; height: 30px; border-radius: 100px;}
#im .c ul li.o{ flex-direction: row-reverse;}
#im .c ul li.m{flex-direction: row;}
#im .c ul li span{ padding: 10px;  border-radius: 5px; display: flex; align-items: center;}
#im .c ul li.o span{ background-color: #3072b9; color: #ffffff;}
#im .c ul li.m span{ background-color: #eeeeee;}
#im .c ul li.m span i{ float: right;}
#im .tools{ height: 30px; background-color: #eeeeee;line-height: 30px;}
#im .tools span{ cursor: pointer;}
#im .send{ height: 110px; background-color: #eeeeee;}
#im .send textarea{ width: 98%; border: none; padding: 5px; height: 70px; outline:none; border-radius: 5px;}
#im .send button{ background-color: #3072b9; border: none; color: #ffffff; cursor: pointer; float: right; padding: 5px 20px; cursor: pointer; margin-top: 5px;}
</style>
