import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import './assets/fonts/iconfont.css'

const store = createStore({
    state () {
      return {
        kefu_box_show: false
      }
    },
    mutations:{
        setKefuStatus(state,val) {
            state.kefu_box_show = val;
        }
    }
})

const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')
