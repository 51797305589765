<template>
  <div id="header">
    <div class="w">
      <div id="nav">
        <router-link to="/">首页</router-link>
        <router-link to="/solution" class="solution" @mouseover="toogleShow" @mouseout="toggleHidden"
          >解决方案</router-link
        >
        <router-link to="/product" @mouseover="toogleShow2" @mouseout="toggleHidden2">产品服务</router-link>
        <router-link to="/news">资讯动态</router-link>
        <router-link to="/about">关于我们</router-link>
        <router-link to="/login">客户中心</router-link>
        <div class="nav-two" v-show="is_show" @mouseover="toogleShow" @mouseout="toggleHidden">
          <ul>
            <li><router-link to="/solution">企业软件定制</router-link></li>
            <li><router-link to="/business">电子商务服务</router-link></li>
            <li><router-link to="/brand">企业品牌开发</router-link></li>
          </ul>
        </div>
        <div class="nav-three" v-show="is_show2" @mouseover="toogleShow2" @mouseout="toggleHidden2">
          <ul>
            <li><router-link to="/product/brand">品牌设计</router-link></li>
            <li><router-link to="/product/it">IT技术服务</router-link></li>
            <li><router-link to="/product/business">电商服务</router-link></li>
            <li><router-link to="/product/marketing">营销推广</router-link></li>
            <li><router-link to="/product/technology">科技服务</router-link></li>
          </ul>
        </div>
      </div>
      <div id="logo">
        <router-link to="/"><img src="../../assets/logo.png" /></router-link>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      hover: false,
      is_show: false,
      hover2: false,
      is_show2: false
    }
  },
  methods: {
    toggleHidden: function () {
      this.is_show = false
      this.hover = false
    },
    toogleShow: function () {
      this.hover = true
      this.is_show = true
    },
    toggleHidden2: function () {
      this.is_show2 = false
      this.hover2 = false
    },
    toogleShow2: function () {
      this.hover2 = true
      this.is_show2 = true
    }
  }
}
</script>

<style>
#header {
  background-color: #ffffff;
  padding: 15px 0px;
}
#nav {
  margin: 0px auto;
  padding: 30px 0px;
  float: right;
  position: relative;
  z-index: 9999;
}
#nav a:link,
#nav a:visited,
#nav a:hover {
  font-size: 1rem;
  padding: 5px 30px;
}
.nav-two {
  position: absolute;
  background-color: #ffffff;
  left: 10%;
  border-radius: 5px;
}
.nav-two ul,
.nav-two ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.nav-two ul {
  padding-top: 30px;
}
.nav-two ul li {
  line-height: 40px;
}
.nav-three {
  position: absolute;
  background-color: #ffffff;
  left: 30%;
  border-radius: 5px;
}
.nav-three ul,
.nav-three ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.nav-three ul {
  padding-top: 30px;
}
.nav-three ul li {
  line-height: 40px;
}
</style>
